<template>
  <ul ref="content" class="content content-list">
    <p v-if="!list || !list.totalActive">No items could be found</p>
    <template v-else>
      <LazyAtomsTags
        v-if="type !== 'entry' && !props.max"
        :tags="tags"
        :type="type"
        :types="types"
        class="content-tags"
      >
        <span class="content-tags-title">
          <LazyAtomsIcon class="content-tags-icon" name="tag" />
          Tags
        </span>
      </LazyAtomsTags>
      <LazyBlocksPagination
        v-if="list.pagination.enabled"
        v-bind="{
          ...list.pagination,
          page,
          types,
        }"
      />
      <TransitionGroup tag="ul" class="content-list" name="content-item">
        <li v-for="(item, index) in list.queried" :key="index" :style="{ animationDelay: delay + (index * 0.1) + 's' }" class="content-item">
          <LazyBlocksEntry v-if="type === 'entry'" v-bind="item" />
          <LazyBlocksItem v-else v-bind="item" />
        </li>
      </TransitionGroup>
      <p v-if="list.pagination.enabled" class="content-detail">
        Showing {{ list.totalActive }} of {{ list.totalQueried }} items
      </p>
      <LazyBlocksPagination
        v-if="list.pagination.enabled"
        v-bind="{
          ...list.pagination,
          page,
          types,
        }"
      />
    </template>
  </ul>
</template>

<script lang="ts" setup>
import type { Ref } from 'vue'

const props = defineProps({
  type: {
    type: String as () => 'article' | 'story' | 'entry',
    required: true,
  },
  delay: { type: Number, default: 0 },
  max: { type: Number, default: -1 },
  offset: { type: Number, default: 0 },
  tags: Array,
  count: Number,
  items: {
    type: Number,
    default: 5,
  },
})

const route = useRoute()

const tag = computed(() => route.query?.tag || '')

const page = computed(() => Number(route.query?.page) || 1)

const { $content } = useNuxtApp()

const types = computed(() => `page_${route.params.page}`)

const list = computed(() => {
  if (!$content) {
    console.log($content)
    return
  }
  const max = props.max || -1
  const offset = props.offset || 0
  const all = $content[props.type]
  const allLimited = all.slice(offset, max + offset)
  const enabled = props.type !== 'entry' && max < 0 && allLimited.length > 1

  const allQueried = tag.value
    ? allLimited.filter((l) => l.tags?.includes(tag.value))
    : allLimited

  const totalQueried = allQueried.length

  const totalPages = Math.ceil(totalQueried / props.items)

  const queried = enabled
    ? allQueried.slice((page.value - 1) * props.items, page.value * props.items)
    : allLimited

  return {
    queried,
    totalQueried,
    totalActive: queried.length,
    pagination: {
      enabled,
      totalPages,
      pages: Array.from({ length: totalPages }, (_, i) => i + 1),
    },
  }
})

const content = ref<HTMLElement>()

watch(
  () => [route.query.tag, route.query.path],
  () => {
    if (process.client) cznScroll(content as Ref<HTMLElement>)
  },
  { immediate: true }
)
</script>

<style lang="scss">
.content {
  position: relative;
  // width: $container-max-width;
  margin-top: 8vh;

  .content {
    &-detail {
      font-size: 1.2rem;
      text-align: center;
    }
    &-tags {
      margin: 0 auto 4rem;
      width: 100%;
      justify-content: center;
      align-items: center;

      &-title {
        display: flex;
        font-size: 0.8rem;
        font-weight: bold;
        // margin-bottom: 0;
        align-items: flex-end;
        text-transform: uppercase;
        letter-spacing: 0.25px;
      }

      &-icon {
        margin-right: $sm;
        font-size: 1.1em;
      }
    }
    &-list {
      position: relative;
      display: block;
    }
    &-item {
      display: block;
      margin-bottom: 10vh;
      left: 0;
      right: 0;

      &-move,
      &-enter-active {
        transition: transition($transition, (opacity));
      }
      &-leave-active {
        position: absolute;
        top: 100%;
      }
      &-enter-from {
        opacity: 0;
      }
      &-leave-to {
        opacity: 0;
      }
    }
  }
}

// .column.first,
.column.sidebar,
.column.second,
.column.third {
  .content {
    width: 100%;
    margin-top: 1rem;

    &:first-child {
      margin-top: 0;
    }

    .content {
      // &-detail {
      // }
      &-tags {

        &-title {
        }

        &-icon {
        }
      }
      &-list {
      }
      &-item {
        margin: 0 0 $lg;
      }
    }
    .item {
      $size: 5rem;
      font-size: 1rem;
      align-items: flex-start;

      .heading {
        transition: transition(var(--transition), (transform, color));
      }

      &:hover,
      &:focus,
      &:active {
        .heading,
        .button {
          color: var(--secondary);
        }
        .item-column-action {
          transform: scale(1.1);
        }
      }
      
      .item {
        &-title {
          font-size: 1.6rem;
        }
        &-date {
          font-size: 0.85rem;
        }
        &-image {
          width: $size;
          height: $size;
          margin-right: $lg;
        }
        &-tags,
        &-action {
          display: none;
        }
        &-column-action {
          display: flex;
          margin-top: $md;
        }
      }
    }
  }
}
</style>
